import { NgModule } from '@angular/core';
import { EmailEditorComponent } from './email-editor.component';

@NgModule({
  declarations: [EmailEditorComponent],
  imports: [
  ],
  exports: [EmailEditorComponent]
})
export class EmailEditorModule { }
